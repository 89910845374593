<template>
        <base-card
            class="py-5"
        >
          <!--Main-Sponsor-->
          <v-container
              :style="$vuetify.breakpoint.mdAndUp ? 'height: 900px' : 'height: 850px'"
              class="d-flex align-center"
          >
            <v-row
                v-for="mainSponsor in mainSponsors"
                :key="mainSponsor.sys.id"
                justify="center"
            >
              <v-col
                  cols="12"
                  md="6"
              >
                <kinesis-container event="move">
                  <kinesis-element  :strength="0.1" type="scale">
                      <v-img
                          :src="img(mainSponsor.logo)"
                          contain
                          aspect-ratio="1"
                          class="rounded-xl"
                      >
                        <template v-slot:placeholder>
                          <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                          >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                  </kinesis-element>
                </kinesis-container>
              </v-col>
              <v-col
                  cols="8"
                  md="6"
                  class="d-flex align-center"
              >
                <v-row class="d-flex">
                <v-card-title
                    class="headline text-uppercase logo ma-1 text-center"
                    style="word-break: break-word"
                    v-html="mainSponsor.name"
                >
                </v-card-title>
                <v-row justify="start" class="px-6 d-flex my-1">
                  <v-chip
                      class="justify-center logoLight ma-1"
                      color="accent"
                      text-color="white"
                      style="max-width: 150px"
                  >
                    {{$t('sponsors.since')}}
                    {{mainSponsor.sponsorSince }}
                  </v-chip>
                  <v-chip
                      v-if="mainSponsor.mainSponsor"
                      class="justify-center logoLight ma-1"
                      color="secondary"
                      text-color="white"
                      style="max-width: 150px"
                  >
                    {{$t('sponsors.mainSponsor')}}
                  </v-chip>
                </v-row>
                <v-skeleton-loader
                    type="paragraph@3"
                    :loading="loading"
                    class="my-1"
                >
                  <template v-slot:default>
                    <v-card-text
                        id="about"
                        class="text-xs-center text-lg-h6 text-sm-subtitle-1 font-weight-light"
                    >
                      {{mainSponsor.description}}
                    </v-card-text>
                  </template>
                </v-skeleton-loader>
                <v-row justify="center" class="pa-6 mb-auto">
                  <v-btn
                      color="secondary"
                      tonal
                      rounded
                      class="logo"
                      :href="mainSponsor.url"
                      target="_blank"
                  >
                    {{$t('sponsors.readMore')}}
                  </v-btn>
                </v-row>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <!--Sponsoren-->
            <v-row justify="center">
            <sponsors v-for="sponsor in sponsors"
                      :key="sponsor.sys.id"
                      :entry="sponsor"

            />
          </v-row>
          <!--Parallax-->
          <v-parallax
              :height="$vuetify.breakpoint.mdAndUp ? 800 : 300"
              :lazy-src="`${require('@/assets/img/gladbeck_gruppenfoto.jpg')}?h=500&fm=jpg&fl=progressive&q=50`"
              :src="`${require('@/assets/img/gladbeck_gruppenfoto.jpg')}?h=1200&fm=jpg&fl=progressive&q=100`"
          >
            <v-overlay
                value="true"
                absolute
            >
              <v-container fluid>
                <v-row justify="center">
                  <v-col
                      class="font-weight-bold white--text text-uppercase headline logo"
                  >
                    #JEMEINSAM
                  </v-col>
                </v-row>
              </v-container>
            </v-overlay>
          </v-parallax>
        </base-card>
</template>

<script>
    import Sponsors from "@/components/sponsors/Sponsors"
    import{KinesisElement, KinesisContainer} from 'vue-kinesis'
    import {fetchData, query_MAINSPONSORS, query_SPONSORS, query_SUPPORTERS} from "@/utils/api"
    import i18n from "@/plugins/i18n"

    export default {
        components: {
          KinesisElement,
          KinesisContainer,
          Sponsors
        },
      data: ()=>({
        loading: true,
        mainSponsors: [],
        sponsors: [],
        supporters: []
      }),
      methods: {
        img(img){
          return img == null ? undefined : img.url
        },
        getMainSponsors: async () => {
          const query = query_MAINSPONSORS(i18n.locale)
          return await fetchData(query, 'sponsorCollection')
        },
        getSponsors: async () => {
          const query = query_SPONSORS(i18n.locale)
          return await fetchData(query, 'sponsorCollection')
        },
        getSupporters: async () => {
          const query = query_SUPPORTERS(i18n.locale)
          return await fetchData(query, 'sponsorCollection')
        }
      },
      async created() {
        this.mainSponsors = await this.getMainSponsors()
        this.sponsors = await this.getSponsors()
        this.supporters = await this.getSupporters()
        this.loading = false

        this.$eventHub.$on('locale-changed', async()=>{
          this.loading = true
          this.mainSponsors = await this.getMainSponsors()
          this.sponsors = await this.getSponsors()
          this.supporters = await this.getSupporters()
          this.loading = false
        })
      },

    }
</script>
