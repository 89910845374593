<template>
  <v-col
      cols="12"
      sm="2"
  >
    <kinesis-container event="move">
      <kinesis-element  :strength="0.1" type="scale">
        <v-card
            tile
            class="ma-6 ma-md-3 rounded-lg"
        >
          <v-img
              :src="img(entry.logo)"
              contain
              aspect-ratio="1"
              style="background-color: white"
              @click="panel[0] = !panel[0]"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-expansion-panels
                v-model="panel"
                flat
            >
              <v-expansion-panel>
                <v-expansion-panel-header
                 color="rgba(23,255,255,0.0)"
                 style="position: absolute"
                >
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-2" style="min-height: 120px">
                  <v-card-title
                      class="subtitle-1 text-uppercase logo ma-1 text-center d-flex justify-center"
                      style="word-break: break-word"
                      v-html="entry.name"
                  >
                  </v-card-title>
                  <v-row justify="center">

                    <v-chip
                        class="justify-center logoLight ma-1"
                        color="accent"
                        text-color="white"
                        style="max-width: 150px"
                    >
                      {{$t('sponsors.since')}}
                      {{entry.sponsorSince }}
                    </v-chip>
                    <v-chip
                        v-if="entry.mainSponsor"
                        class="justify-center logoLight ma-1"
                        color="secondary"
                        text-color="white"
                        style="max-width: 150px"
                    >
                      {{$t('sponsors.mainSponsor')}}
                    </v-chip>
                    <v-card-text
                        class="text--white ma-1"
                    >
                      {{entry.description}}
                    </v-card-text>
                  </v-row>
                  <v-card-actions
                      class="d-flex justify-center mt-1"
                  >
                    <v-btn
                        color="secondary"
                        tonal
                        rounded
                        class="logo"
                        :href="entry.url"
                        target="_blank"
                    >
                      {{$t('sponsors.readMore')}}
                    </v-btn>
                  </v-card-actions>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-img>

        </v-card>
      </kinesis-element>
    </kinesis-container>
  </v-col>
</template>

<script>
import{KinesisElement, KinesisContainer} from 'vue-kinesis'

    export default {
        name: "Sponsors",
      components: {
        KinesisElement,
        KinesisContainer
      },
        props:{
            entry: {
                type: Object,
                default: () => ({
                  name: '',
                  sponsorSince: '2022',
                  mainSponsor: false,
                  description: '',
                  img: '',
                  url: ''
                })
            }
        },
      data: ()=>({
        panel: [false]
      }),
      methods: {
        img(img){
          return img == null ? undefined : img.url
        }
      }
    }
</script>

<style lang="sass" scoped>
@import 'src/styles/variables.scss'

.v-application
  .display-1
    font-family: $logo
  .title
    font-family: $logo
  .text-md-h4
    font-family: $logo

.logoLight
  font-family: $logoLight

.img-gray
  filter: grayscale(100%)
  -webkit-filter: grayscale(100%)
  transition: filter 2s
</style>
